import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title={`O nas`}
        description={`W Balicka Clinic pracujemy z pasją i ogromnym zaangażowaniem. Dzięki najnowszym osiągnięciom nauki i medycyny potrafimy całościowo zatroszczyć się nie tylko o dobry wygląd naszych pacjentów, ale przede wszystkim o ich zdrowie fizyczne i psychiczne.`}
      />

      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xs-12">
              <img
                src="../img/logo.svg"
                alt="logo"
                className="brand-logo-centered"
              />

              <div className="about-text">
                <h1 className="offer-item-title">O nas</h1>

                <p>
                  W Balicka Clinic pracujemy z pasją i ogromnym zaangażowaniem.
                  Dzięki najnowszym osiągnięciom nauki i medycyny potrafimy
                  całościowo zatroszczyć się nie tylko o dobry wygląd naszych
                  pacjentów, ale przede wszystkim o ich zdrowie fizyczne i
                  psychiczne.
                  <br />
                  Wyróżnia nas profesjonalizm, wieloletnie lekarskie
                  doświadczenie i empatia. W naszej klinice pacjenci mogą oprócz
                  profesjonalnych zabiegów na najwyższej jakości preparatach i
                  certyfikowanych sprzętach mogą także liczyć na ciepłą
                  atmosferę, klimatyczny relaks oraz intymność i poczucie
                  dyskrecji. A piękne kameralne wnętrza zaspokoją zmysły nawet
                  najbardziej wymagających pacjentów.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-xs-12">
              <img
                className="about-img"
                src={"../img/team/luiza-o-nas.png"}
                alt="Luiza"
              />
              <div className="about-text">
                <p className="text-center">
                  Zapraszamy
                  <br />
                  Dr n med. Luiza Balicka - Adamik
                </p>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <a href="/zobacz-klinike" className="cursor-pointer">
                <div className="about-clinic-img-container about-clinic-img">
                  <img
                    className="about-clinic-img"
                    src={"../img/clinic/gabinet.jpg"}
                    alt="Gabinet"
                  />
                  <div className="about-clinic-img-overlay"></div>
                  <div className="about-clinic-img-overlay-button">
                    <span>Zobacz klinikę</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
